import React, { Component } from 'react'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

//--Chart Style Options--//
Chart.defaults.plugins.legend.display = false;
//--Chart Style Options--//


export default class BarGraph extends Component {

    chartRef = React.createRef();

    componentDidMount() {
        this.buildChart();
    }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");
        const { data, labels } = this.props;

        new Chart(myChartRef, {
            type: "bar",
            data: {
                //Bring in data
                labels: labels,
                datasets: [
                    {
                        label: " Avg. Price",
                        data: data,
                        borderColor: '#3479ae',
                        backgroundColor: '#3479ae',
                    }
                ]
            },
            options: {
                //Customize chart options
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    y: {
                        ticks: {
                            // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                            callback: function (val, index) {
                                // Hide the label of every 2nd dataset
                                var options = {
                                    style: 'currency', currency: 'USD', maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                };
                                return new Intl.NumberFormat('en-US', options).format(val);
                            },
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                var label = context.dataset.label || '';
        
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, }).format(context.parsed.y);
                                }
                                return label;
                            },
                        }
                    }
                }
            }
        });
    }

    render() {
        return (
            <div>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}
import React from "react"
import styled from 'styled-components'

const CircleStats = (props) => {
  return (
    <CircleContainer>
      <Box>
        <Stat>
          <StatTitle>{props.odometerAvg + " km"}</StatTitle>
          <StatTag>Avg. mileage</StatTag>
        </Stat>
      </Box>
      <Box>
        <Stat>
          <StatTitle>${props.priceMedian}</StatTitle>
          <StatTag>Median. price</StatTag>
        </Stat>
      </Box>
      <Box>
        <Stat>
          <StatTitle>{props.daysToSell}</StatTitle>
          <StatTag>Avg. days to <br /> sell</StatTag>
        </Stat>
      </Box>
      <Box>
        <Stat>
          <StatTitle>{props.count}</StatTitle>
          <StatTag>Cars in sample</StatTag>
        </Stat>
      </Box>
    </CircleContainer>
  )
};

export default CircleStats

const CircleContainer = styled.div`
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin-top: 30px;

    @media (min-width: 320px) and (max-width: 1050px) {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
    }

    @media (max-width: 319px) {
        grid-template-columns: 100%;
        grid-template-rows: auto;
    }

`;


const Box = styled.div`
   position: relative;
   margin: 0px;
   padding: 0px;
   height: 200px;
   width: 200px;
   margin-bottom: 30px;
   justify-self: center;

   @media (min-width: 320px) and (max-width: 1050px) {

    height: 150px;
    width: 150px;
    
}


`


const Stat = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 150px;
    height: 150px;
    text-align: center;
    background-color: #f8f8f9;
    clip-path: circle(75px at center); 
`

const StatTitle = styled.div`
    margin-top: 45px;
    font-size: 1.5rem;
    color: #3479ae;
    font-weight: 400;
    letter-spacing: -0.1rem;
`

const StatTag = styled.div`
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: -0.1rem;
`



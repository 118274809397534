import React from "react"
import { graphql, Link, navigate } from 'gatsby'
import Img from "gatsby-image"
import Layout from "../components/Layout/Layout"
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Highlight from '../components/Listings/Highlight'
import Paragraph1 from '../components/Type/Paragraph1'
import Grid2Auto from '../components/Layout/Grid2/Grid2Auto'
import GridItem from '../components/Layout/GridItem/GridItem'
import SEO from "../components/Seo/Seo"
import styled from 'styled-components'
import ValuationPageForm from '../components/ValuationPageForm/ValuationPageForm'
import Heading2 from '../components/Type/Heading2'
import Heading3 from '../components/Type/Heading3'
import Heading4 from '../components/Type/Heading4'
import BarGraph from '../components/Chart/barGraph'
import BadgeButton from '../components/Layout/Button/RemoveButton'

import MakeModelBadgeTable from '../components/Valuation/MakeModelBadgeTable'
import CircleStats from '../components/Valuation/CircleStats'
import Disclaimer from '../components/Valuation/Disclaimer'


const ValuationPage = ({ location, data, pageContext }) => {

  const formatNumber = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
    try {
      if (!amount) return 0;
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e);
    }
  }

  const displayArray = (array) => {

    let filtered = [];
    for (var i = 0; i < array.length; i++) {
      if (array[i] != null) {
        filtered.push(array[i]);
      }
    }

    let s = filtered.toString();
    s = s.replace(",", " & ");
    return s;
  }

  const slugger = (part) => {
    return part.replace(/\s+/g, '-').toLowerCase();
  }

  const confidence = (sampleCount) => {
    let confidenceLevel = "";
    if (sampleCount < 10) {
      confidenceLevel = "low confidence";
    } else if (sampleCount < 20) {
      confidenceLevel = "low-medium confidence";
    } else if (sampleCount < 30) {
      confidenceLevel = "medium confidence";
    } else if (sampleCount < 40) {
      confidenceLevel = "medium-high confidence";
    } else if (sampleCount >= 40) {
      confidenceLevel = "high confidence";
    }
    return confidenceLevel;
  }

  const hangeBadgeClick = (badge) => {
    let slug = "/price/" + slugger(pageData.make) + "/" + slugger(pageData.model) + "/" + slugger(badge) + "/";
    navigate(slug);

  }

  const pageData = data.valuation;
  const priceGuideHigh = pageData.priceGuideHigh;
  const priceGuideLow = pageData.priceGuideLow;
  const distinctBadge = [...new Set(data.allValuation.edges.map(x => x.node.badge))];


  // create a list of badge button, additional navigation
  const badges = distinctBadge.map((badge, i) => {
    return (
      <BadgeButton key={i} type="button" onClick={() => hangeBadgeClick(badge)} >{badge}</BadgeButton>
    );
  });


  let badgeTxt = (pageData.badge !== "(No Badge)") ? pageData.badge : "";
  let seriesTxt = (pageData.series !== "(No Series)") ? pageData.series : "";

  let tagline = pageData.make + " " +
    pageData.model + " " +
    badgeTxt + " " +
    seriesTxt + " " +
    displayArray(pageData.bodyType) + " " +
    "private sale price guide in Australia";

  let graphTitle = pageData.year + " " +
    pageData.model + " " +
    badgeTxt + " " +
    pageData.series;


  let breadcrumb = <div style={{ color: 'white' }}>
    Valuations: &nbsp;
    <Link style={{ color: 'white' }} to="/price/">Make</Link> &nbsp; / &nbsp;
    <Link style={{ color: 'white' }} to={slugger("/price/" + pageData.make + "/")}>{pageData.make}</Link> &nbsp; / &nbsp;
    <Link style={{ color: 'white' }} to={slugger("/price/" + pageData.make + "/" + pageData.model + "/")}>{pageData.model}</Link> &nbsp; / &nbsp;
    <Link style={{ color: 'white' }} to={slugger("/price/" + pageData.make + "/" + pageData.model + "/" + pageData.badge + "/")}>{pageData.badge}</Link>
  </div>


  return (
    <Layout>

      <SEO title={pageData.carName + " price Australia"}
        description={"Valuation for " + pageData.carName}
        location={location}
        image={data.badgeImg.childImageSharp.fixed.src}
      />

      <PageHeader marginBottom="10px"
        title={pageData.carName + " price"}
        text={tagline}
        breadcrumb={breadcrumb}
      />

      <SectionPageMargin>
        <Grid2Auto >
          <GridItem order="1" >

            <IntroBlock>

              <LogoBlock >
                <Img fixed={data.logoImg.childImageSharp.fixed} />
              </LogoBlock>

              <div>
                <Heading2>${formatNumber(priceGuideLow)} - ${formatNumber(priceGuideHigh)}<sup style={{ fontSize: '1.1rem' }}>*</sup></Heading2>

            
                <Paragraph1>
                  Summon price guide for {pageData.model + " "} 

                  {badgeTxt + " " +
                    seriesTxt + " " +
                    displayArray(pageData.bodyType) + " with " +
                    formatNumber(pageData.odometerAvg) + " km in Australia"}
                </Paragraph1>

             

                
              </div>
            </IntroBlock>

            <Paragraph1>
              This is a price estimate and valuation based on based on private and dealer sales and advertisements 
              from the past 12 months. The estimate is based on the average mileage
               of {pageData.year} {pageData.make} {pageData.mode} {badgeTxt} {seriesTxt} vehicles in our database.
            </Paragraph1>

            <Paragraph1>
              This price range is based on a sample of {pageData.count} matching Australian cars that have sold / been listed for sale
              in the last 12 months. We have <strong>{confidence(pageData.count)}</strong> in this price guide.
            </Paragraph1>


            <Highlights>
              <Highlight title="Body Type" value={displayArray(pageData.bodyType)} />
              <Highlight title="Transmission" value={displayArray(pageData.transmission)} />
              <Highlight title="Engine" value={displayArray(pageData.engine)} />
              <Highlight title="Series" value={pageData.series} />
            </Highlights>

            <CircleStats
              odometerAvg={formatNumber(pageData.odometerAvg)}
              priceMedian={formatNumber(pageData.priceMedian)}
              daysToSell={pageData.daysToSell}
              count={pageData.count}
            />

            <Heading4>
              Explore {graphTitle} based on mileage
            </Heading4>
            <BarGraph data={pageData.graphData} labels={pageData.graphLabels} />

            <Heading4>Explore {pageData.model} {badgeTxt} based on year and series</Heading4>
            <MakeModelBadgeTable
              cars={data.allValuation}
              model={pageData.model}
              badge={pageData.badge}
              id={pageData.id}
            />

            <Heading4>{pageData.model} badges</Heading4>
            {badges}

            <Disclaimer />

          </GridItem>
          <GridItem order="2">

            <div style={{ textAlign: 'center', marginTop: '30px', marginBottom: '30px' }}>
              <Img fixed={data.badgeImg.childImageSharp.fixed} />
            </div>

            <FormContainer>
              <Heading3>Request Valuation</Heading3>
              <ValuationPageForm make={pageData.make} page={pageData.carName} />
            </FormContainer>

          </GridItem>
        </Grid2Auto>
      </SectionPageMargin>

    </Layout>
  )
}

export default ValuationPage

const IntroBlock = styled.div`
  display: flex;

  @media (max-width: 600px) {
    display: block; 
  }  

`;

const LogoBlock = styled.div`
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  margin-top: 40px;
  width: 160px;
  height: 115px;
  text-align: center;
  margin-right: 20px;

  @media (max-width: 600px) {
    width: 100%;
  }


`;

const Highlights = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: left;
    grid-template-rows: auto auto;
  }

`;

const FormContainer = styled.div`
  background-color: #f8f8f9;
  padding: 16px;
  width: 300px;
`;



export const PageQuery = graphql`
  query ($id: String!, $make: String!, $model: String!, $logo: String!, $badge: String) {

    valuation(id: {eq: $id}) {
      series
      badge
      bodyType
      carName
      count
      daysToSell
      engine
      fuel
      graphData
      graphLabels
      id
      make
      model
      my
      odometerAvg
      odometerMedian
      priceGuideHigh
      priceGuideLow
      priceMedian
      priceSD
      stepSize
      transmission
      year
    }

    allValuation(filter: {make: {eq: $make}, model: {eq: $model}}) {
      edges {
         node {
           id
           carName
           priceMedian
           odometerAvg
           badge
           make
           model
           series
           year
         }
       }
      }

      logoImg: file(relativePath: { eq: $logo }) {
        childImageSharp {
          fixed(width: 150) {
          ...GatsbyImageSharpFixed
          }
        }
      }

      badgeImg: file(relativePath: { eq: $badge }) {
        childImageSharp {
          fixed(width: 204) {
          ...GatsbyImageSharpFixed
          }
        }
      }

  }`


import React from "react"
import styled from 'styled-components'

const Disclaimer = (props) => {
  return (
    <Disclaim >
      * All price guides are based on Excluding Government Charge (EGC) sale prices. 
      <br />&nbsp;<br />
      <u>Disclaimer:</u> Whilst all reasonable care is taken to produce our price guides, much of the data is from third-party sources, 
      and Summon do not warrant the data is reliable, accurate or suitable for your purpose.  
      To the maximum extent permitted by law, Summon is not liable for any loss or damages 
      relating to your use of this information.
  </Disclaim>
  )
};


export default Disclaimer

const Disclaim = styled.p`
  font-size: .9rem;
  color: #666666;
  margin-top: 60px;
`;


import styled from 'styled-components';

const RemoveButton = styled.button.attrs(props => ({
  type: props.type || 'button',
  bgColor: props.bgColor || '#3479ae'
}))`
background-color: ${props => props.bgColor};
outline: none;
border:${props => props.bgColor} 1px solid;
color: white;
padding: 5px 10px;
margin-right: 10px;
margin-bottom: 5px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: .8rem;
font-weight: bold;
border-radius: 14px;
cursor: pointer;

cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`
export default RemoveButton;
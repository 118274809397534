import styled from 'styled-components';

const ActionControl = styled.div.attrs(props => ({
  marginTop: props.marginTop || '20px',
  marginBottom: props.marginBottom || '40px',
  align: props.align || 'left'
}))`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  text-align: ${props => props.align};
  width: 100%;

`
export default ActionControl;



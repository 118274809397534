import styled from 'styled-components'

const Input = styled.input.attrs(props => ({
  type: props.type || 'text'
}))`
  outline: none;
  width: 285px;
  font-size: 1.1rem;
  background-color: white;
  color:  black;
  padding: 5px 5px 5px 12px;
  border: #CCCCCC solid 1px;
  margin-bottom: 0.5rem;
`
export default Input;

import React from "react"
//import styled from 'styled-components'
import { Link } from 'gatsby'
import styles from './MakeModelBadgeTable.module.css';



const MakeModelBadgeTable = (props) => {


  const formatNumber = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
    try {
      if (!amount) return 0;
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e);
    }
  }


  // create a list of cars with same make and model 
  let sameMakeModelBadge = props.cars.edges.filter(item => {

    if (item.node.model === props.model && item.node.badge === props.badge) {
      return true;
    } else {
      return false;
    }
  });

  // order newest to oldest
  sameMakeModelBadge.sort((a, b) => {
    return b.node.year - a.node.year;
  });

  // make a table from sameMakeModelBadge
  const sameMakeModelBadgeCars = sameMakeModelBadge.map((edge, i) => {
    let slug = edge.node.carName.replace(/\s+/g, '-').toLowerCase();
    let moreDetails = "";

    if (props.id === edge.node.id) {
      moreDetails = "Selected";
    } else {
      moreDetails = <Link to={"/price/" + slug + "/"}>View</Link>;
    }
    return (
      <tr key={"smm" + i} className={styles.v_tr}>
        <td label="Year" className={styles.v_td}>{edge.node.year}</td>
        <td label="Median price" className={styles.v_td}>${formatNumber(edge.node.priceMedian)}</td>
        <td label="Avg. mileage" className={styles.v_td}>{formatNumber(edge.node.odometerAvg)} km</td>
        <td label="Series" className={styles.v_td}>{edge.node.series}</td>
        <td label="Details" className={styles.v_td}>{moreDetails}</td>
      </tr>
    );

  });

  return (
    <table className={styles.v_table} width="100%">
      <thead className={styles.v_thead}>
        <tr className={styles.v_tr}>
          <th className={styles.v_th}>
            Year
          </th>
          <th className={styles.v_th}>
            Median price
          </th>
          <th className={styles.v_th}>
            Avg. mileage
          </th>
          <th className={styles.v_th}>
            Series
          </th>
          <th className={styles.v_th}>
            Details
          </th>
        </tr>
      </thead>
      <tbody className={styles.v_tbody}>
        {sameMakeModelBadgeCars}
      </tbody>
    </table>
  )
}

export default MakeModelBadgeTable

/*

const TR = styled.tr`
  &: nth-child(odd) {
    background-color: #f8f8f9;
  }
`;

const TH = styled.th`
    text-align: center;
    padding: 10px;
`;

const TD = styled.td`
    text-align: center;
    padding: 10px;
`;

*/

